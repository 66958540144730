<template>
  <div v-if="isOpen">
    <b-modal
      centered
      :title="isUpdate ? 'Update Newbie Mission' : 'Create Newbie Mission'"
      v-model="isOpen"
      id="modal-xl"
      size="xl"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <validation-observer
          ref="createForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Mission Name"
                  label-for="h-mission-name"
                  label-cols-md="3"
                >
                  <ui-component :component-data="model.name" class="border" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Mission Description"
                  label-for="h-mission-description"
                  label-cols-md="3"
                >
                  <ui-component
                    :component-data="model.description"
                    class="border"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group label="Ordinal Of Week" label-for="h-reward" label-cols-md="3">
              <b-form-select v-model="model.ordinal_of_week" :options="ordinals" />
            </b-form-group>

            <b-form-group label="Category" label-for="h-reward" label-cols-md="3">
              <b-form-select v-model="model.category" :options="categories" />
            </b-form-group>

            <b-form-group label="Lesson" label-for="h-reward" label-cols-md="3">
              <!-- <b-form-select v-model="model.lesson_id" :options="lessons" /> -->
              <multi-select :multiple="false" id="lesson_select" v-model="model.lesson_id" :options="lessons"
                @search-change="lessonSearch"
              />
            </b-form-group>

            <b-form-group v-if="model.category && model.category !== 'arena'" label="Lesson step" label-for="h-reward" label-cols-md="3">
              <b-form-select v-model="model.lesson_step" :options="lessonSteps" />
            </b-form-group>
            <b-form-group v-else
              label="Level"
              label-cols-md="3"
            >
              <b-form-select
                v-model="model.user_level"
                :options="user_levels"
              />
            </b-form-group>

            <b-form-group label="Mission key" label-for="h-reward" label-cols-md="3">
              <b-form-input v-model="model.mission_key" />
            </b-form-group>

            <div v-for="(item, index) in model.rewards" :key="index">
              <validation-provider
                name="Reward type"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group label="Reward type" label-for="h-reward" label-cols-md="3">
                  <b-form-select
                    v-model="item.type"
                    :options="missionRewards"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                name="Reward amount"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group label="Reward amount" label-for="h-reward" label-cols-md="3">
                  <b-form-input
                    v-model="item.amount"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button
          :disabled="!!isLoading"
          type="submit"
          variant="primary"
          @click="onSave"
        >
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import { mapGetters, mapMutations } from 'vuex'
import missionRewardService from "../../../mission_reward/service";
import defaultObject from "../../defaultObject.js";
import pronunLessonService from "../../../../pronun/service";
import breakingLessonService from "../../../../breaking_lesson/service"
import arenaStagesService from "../../../../arena_v2/stage/service"
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { categories, selectRewards, ordinals, user_levels } from '../../constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      isLoading: false,
      model: {},
      lessons: [],
      lessonSteps: [],
      missionRewards: [],
      categories,
      ordinals,
      user_levels,
      lesson_search: null
    };
  },

  computed: {
    ...mapGetters('newbieMission', [
      'arenaStages',
      'pronunLessons',
      'breakingLessons',
    ])
  },

  watch: {
    "model.category": function (newValue) {
      this.lessons = []
      if (newValue) {
        this.getLessons(newValue)
      }
    },

    "model.lesson_id": function (newValue) {
      this.lessonSteps = []
      if (newValue) {
        this.prepareLessonSteps(newValue)
      }
    },
  },

  async created() {
    this.getMissionRewards();
  },

  methods: {
    ...mapMutations('newbieMission', [
      'setArenaStages',
      'setPronunLessons',
      'setBreakingLessons',
    ]),

    async getMissionRewards() {
      const { data } = await missionRewardService.getAll()
      if (data && data.data.list && data.data.list.length) {
        this.missionRewards = data.data.list.map(reward => {
          return {
            text: reward.type,
            value: reward.type,
          }
        })
        return
      }

      this.missionRewards = selectRewards
    },

    openCreateModal() {
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.isOpen = true;
      this.isUpdate = false;
    },

    async openUpdateModal(newbieMission) {
      this.model = newbieMission;
      this.isOpen = true;
      this.isUpdate = true;
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        this.isOpen = true;
        this.isLoading = true;
        if (!this.isUpdate) {
          try {
            let response = await service.create(this.model);
            if (response.data.type === "DATA") {
              this.$store.dispatch("pushSuccessNotify", {
                text: "Newbie mission created!",
              });
            } else {
              this.$store.dispatch("pushErrorNotify", {
                text: "Error created newbie mission!",
              });
            }
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          try {
            await service.update({ data: JSON.stringify(this.model) });
          } catch (e) {
            this.isLoading = false;
          }
        }
        this.isLoading = false;
        this.isOpen = false;
        this.$emit("loadData");
      }
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.$emit("loadData");
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    async getLessons() {
      let category = this.model.category;
      if (!category) { return }
      switch(category) {
        case 'arena':
          // if (this.arenaStages.length) {
          //   this.lessons = JSON.parse(JSON.stringify(this.arenaStages))
          //   return
          // }
          await this.getArenaStages()
          break

        case 'pronun':
          if (this.pronunLessons.length) {
            this.lessons = JSON.parse(JSON.stringify(this.pronunLessons))
            return
          }
          await this.getPronunLessons()
          break

        case 'breaking':
          if (this.breakingLessons.length) {
            this.lessons = JSON.parse(JSON.stringify(this.breakingLessons))
            return
          }
          await this.getBreakingLessons()
          break
      }
    },

    async prepareLessonSteps(lesson) {
      if (!this.model.category){
      }
      switch(this.model.category) {
        case 'pronun':
          if (!this.pronunLessons.length) {
            await this.getPronunLessons()
          }
          this.getLessonSteps(lesson, this.pronunLessons)
          break

        case 'breaking':
          if (!this.breakingLessons.length) {
            await this.getBreakingLessons()
          }
          this.getLessonSteps(lesson, this.breakingLessons)
          break
      }
    },
    lessonSearch(key) {
      this.lesson_search = Number(key);
      if (this.model.category === 'arena') {
        this.getLessons();
      }
    },
    async getArenaStages() {
      let query = { is_published: true };
      if (this.lesson_search && Number.isInteger(this.lesson_search)) {
        query.stage_number = this.lesson_search - 1;
      }
      const response = await arenaStagesService.getList({
        query,
        page: 1,
        limit: 60
      });
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: 'Stage ' + (item.stage_number + 1),
          };
        });
      }
      this.setArenaStages(JSON.parse(JSON.stringify(this.lessons)))
    },

    async getPronunLessons() {
      const response = await pronunLessonService.getAll({ order_by: 'display_order', order: 1});
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.display_order ? `${item.display_order + 1}: ` + item.title : item.title,
            steps: item.steps
          };
        });
      }
      this.setPronunLessons(JSON.parse(JSON.stringify(this.lessons)))
    },

    async getBreakingLessons() {
      const response = await breakingLessonService.getAll({ order_by: 'display_order', order: 1});
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.display_order ? `${item.display_order + 1}: ` + item.title : item.title,
            steps: item.steps
          };
        });
      }
      this.setBreakingLessons(JSON.parse(JSON.stringify(this.lessons)))
    },

    getLessonSteps(lesson, lessons) {
      const lessonFound = lessons.find(item => item.value === lesson)
      if (!lessonFound || !lessonFound.steps.length) {
        this.lessonSteps = []
        return
      }
      this.lessonSteps = lessonFound.steps.map(step => {
        return {
          text: step.name,
          value: step.key
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
</style>
