module.exports = {
    categories: [
        {
            text: "---",
            value: "",
        },
        {
            text: "Arena",
            value: "arena",
        },
        {
            text: "Pronun",
            value: "pronun",
        },
        {
            text: "Breaking",
            value: "breaking",
        },
    ],
    selectRewards: [
        {
            text: "Gem",
            value: "GEM",
        },
        {
            text: "Token",
            value: "TOKEN",
        },
    ],
    ordinals: [
        {
            text: "Day 1",
            value: 1
        },
        {
            text: "Day 2",
            value: 2
        },
        {
            text: "Day 3",
            value: 3
        },
        {
            text: "Day 4",
            value: 4
        },
        {
            text: "Day 5",
            value: 5
        },
        {
            text: "Day 6",
            value: 6
        },
        {
            text: "Day 7",
            value: 7
        },
    ],
    user_levels: [
      { text: "all", value: null },
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "3", value: 3 },
      { text: "4", value: 4 },
      { text: "5", value: 5 },
      { text: "6", value: 6 },
    ],
}
