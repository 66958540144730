<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Newbie Mission" class="mb-3" />
      </b-col>
    </b-row>
    <b-media>
      <section class="py-3">
        <div class="d-flex justify-content-between">
          <h2 class="mb-2">Newbie Mission</h2>
          <div>
            <loading-button
              class="mr-1"
              icon="SettingsIcon"
              variant="success"
              size="md"
              @click="openConfigPublicMissionModal"
            >
              Config public mission
            </loading-button>
            <loading-button
              icon="CheckCircleIcon"
              variant="warning"
              size="md"
              :disabled="!is_mission_changed"
              @click="updateDisplayOrder"
            >
              Update Display Mission
            </loading-button>
            <loading-button
              class="ml-1"
              icon="PlusIcon"
              variant="primary"
              size="md"
              @click="openCreateNewbieMissionModal"
            >
              Add
            </loading-button>
          </div>
        </div>
      </section>
    </b-media>
    <b-card>
      <mission-newbie-table
        :newbie_missions="newbie_missions"
        @getList="getList"
        @changeMission="changeMission"
      />
    </b-card>
    <div class="d-flex align-items-center flex-wrap mt-3">
      <div class="d-flex align-items-center mb-0">
      <span class="text-nowrap ">
        Showing
      </span>
      <b-form-select
        v-model="items_per_page"
        :options="['3','9', '18', '36', '108', '300', '3000']"
        class="mx-1"
        @input="getList"
      />
      <span class="text-nowrap"> of total: {{ total_items }} rows </span>
    </div>
    <b-pagination
      class="mb-0 ml-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_per_page"
    />
    </div>
    
    <mission-newbie-form-modal ref="newbieMissionFormModal" @loadData="getList" />
    <config-public-mission-form-modal ref="configPublicMissionFormModal" />
  </div>
</template>

<script>
import service from "../service";
import MissionNewbieTable from "./_components/MissionNewbieTable.vue";
import MissionNewbieFormModal from "./_components/MissionNewbieFormModal.vue";
import ConfigPublicMissionFormModal from "./_components/ConfigPublicMissionFormModal.vue";
import draggable from "vuedraggable";

export default {
  components: {
    MissionNewbieTable,
    MissionNewbieFormModal,
    ConfigPublicMissionFormModal,
    draggable,
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_per_page: 108,
      newbie_missions: [],
      editLanguage: null,
      is_mission_changed: false,
    };
  },

  watch: {
    newbie_missions(value, old_value) {
      if (!old_value.length) {
        return;
      }
      if (this.is_mission_changed === false) {
        this.is_mission_changed = true;
      }
    },
    current_page(){
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_per_page,
      });
      if (response.data.type === "DATA") {
        this.newbie_missions = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },

    changeMission(value) {
      this.newbie_missions = value;
    },

    async updateDisplayOrder() {
      const res = await service.updateDisplayOrder({
        list: this.newbie_missions,
      });
      if (res.data && res.data.type === "SUCCESS") {
        await this.getList();
      }
    },

    goToCreateNewbieMission() {
      this.$router.push({
        name: "mission-newbie-create",
      });
    },

    openCreateNewbieMissionModal() {
      this.$refs.newbieMissionFormModal.openCreateModal();
    },

    openConfigPublicMissionModal() {
      this.$refs.configPublicMissionFormModal.openUpdateModal();
    },

    changeLimit(data) {
      this.items_per_page = data.currentPerPage;
      this.$emit('changeLimit', data.currentPerPage);
    },
  },
};
</script>
